import api from './request';

// 查询项目列表
export const getProjectList = (data: { projectName?: string; page?: Basic.Page }) =>
  api.post<Project.ListData>('/auth-admin/sys/query', data);

// 更新/新增
export const createOrUpdateProject = (data: Project.ProjectItem) =>
  api.post('/auth-admin/sys/createOrUpdate', data);

// 项目详情（暂时不用）
export const getProjectInfo = (projectId: number) =>
  api.post('/auth-admin/sys/info', { projectId });

// 删除项目
export const deleteProject = (projectId: number) =>
  api.post<{ success: number }>('/auth-admin/sys/delete', { projectId });
